<template lang="pug">
structure-h1(:title="$t('list_eans.title1')")
structure-card
  b {{ $t('list_eans.box1_title') }}
  p {{ $t('list_eans.box1_sub_title') }}
structure-h1(:title="$t('list_eans.title2')")
structure-card.flex.justify-between.flex-wrap
  .flex.filters.flex-none.items-center
    input#null(type="radio", :value="null", v-model="filter")
    label(for="null") {{ $t('list_eans.filter_all') }}
    input#used(type="radio", :value="true", v-model="filter")
    label(for="used") {{ $t('list_eans.filter_used') }}
    input#unused(type="radio", :value="false", v-model="filter")
    label(for="unused") {{ $t('list_eans.filter_notused') }}

  .flex.items-center.flex-wrap
    structure-input-select.mr-5.w-250px(
      v-model="order_selected",
      position="bottom",
      :listOptions="listOrders",
      :label="$t('list_eans.filter_order')"
    )
    structure-input.w-250px(
      v-model="query_info",
      :label="$t('list_eans.filter_info')",
      name="search"
    )
structure-card.p-0.overflow-hidden
  table.w-full
    thead
      tr.text-center
        th {{ $t('list_eans.table_used') }}
        th EAN
        th {{ $t('list_eans.table_info', { number: '1' }) }}
        th {{ $t('list_eans.table_info', { number: '2' }) }}
        th {{ $t('globals.options') }}
    tbody.text-center
      tr(v-for="ean in listEansFiltereds", :key="ean._id")
        td 
          input(
            type="checkbox",
            v-model="ean.data_user_active",
            @change="updateEan(ean)"
          )
        td {{ ean.code }}
        td 
          structure-input.-mt-1(
            v-model="ean.data_user_info_1",
            :label="null",
            :name="ean._id",
            @change="updateEan(ean)"
          )
        td 
          structure-input.-mt-1(
            v-model="ean.data_user_info_2",
            :label="null",
            :name="ean._id",
            @change="updateEan(ean)"
          )
        td
          .button-group
            div(@click="openModalEditor(ean.code)") {{ $t('globals.print') }}
ModalEditorEan(
  :ean="`${ean_selected_to_edit}`",
  v-if="modalOpen",
  v-on:modalClose="modalOpen = false"
)
</template>
<script>
import ModalEditorEan from "./modalEditorEan";
import userRepository from "@/repositories/userRepository";

export default {
  name: "eans",
  components: {
    ModalEditorEan,
  },
  data() {
    return {
      filter: null,
      query_info: "",
      order_selected: {
        text: this.$t("list_eans.filter_order_value"),
        value: null,
      },
      ean_selected_to_edit: null,
      modalOpen: false,
    };
  },
  watch: {
    filter(nValue) {
      this.$store.commit("eans/setQueryFilter", nValue);
    },
    order_selected(nValue) {
      this.$store.commit("eans/setQueryIdOrder", nValue.value);
    },
    query_info(nValue) {
      this.$store.commit("eans/setQuerySearch", nValue);
    },
  },
  methods: {
    setDate: (d) => {
      const date = new Date(d);
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    updateEan(item) {
      userRepository
        .updateMeEan({
          ean_id: item._id,
          is_active: item.data_user_active,
          data_1: item.data_user_info_1,
          data_2: item.data_user_info_2,
        })
        .then(() => {
          this.$toast.success(this.$t("list_eans.responses_saved_true"), {
            duration: 600,
          });
        })
        .catch(() => {
          this.$toast.error(this.$t("profile.response_bad"));
        });
    },
    openModalEditor(ean_selected) {
      this.ean_selected_to_edit = ean_selected;
      this.modalOpen = true;
    },
  },
  computed: {
    listOrders() {
      const listOrders = this.$store.getters["orders/listOrders"].map(
        (order) => {
          return {
            text: `${this.setDate(order.created_at)} - ${order.total_eans}`,
            value: order._id,
          };
        }
      );
      return [
        {
          text: this.$t("list_eans.filter_order_value"),
          value: null,
        },
        ...listOrders,
      ];
    },
    listEansFiltereds() {
      return this.$store.getters["eans/listEans"];
    },
  },
};
</script>

<style lang="scss" scoped>
.filters {
  input[type="radio"] {
    + label {
      @apply ml-1 mr-3 mt-1;
    }
  }
}

.w-250px {
  width: 250px;
}
</style>