<template lang="pug">
teleport(to="body")
  .modal
    .w-full.max-w-2xl.bg-white.rounded-md.shadow-2xl.flex.flex-col
      .p-3.border-b.border-gray-100.flex.justify-between.items-center.text-gray-400
        .font-bold.text-sm {{ $t('modal_print_eans.title1', { ean_code: ean }) }}
        font-awesome-icon.cursor-pointer(
          :icon="['fas', 'times-circle']",
          @click="$emit('modal-close')"
        )
      .p-3.flex.justify-center.border-b.border-gray-100
        img#barcode(ref="code_to_print")
      .p-3.grid.grid-cols-2.gap-4.border-b.border-gray-100
        .flex.flex-col
          label {{ $t('modal_print_eans.option_width') }}
          input(type="range", min="2", max="5", v-model="obj_code.width")
        .flex.flex-col
          label {{ $t('modal_print_eans.option_height') }}
          input(type="range", min="50", max="150", v-model="obj_code.height")
        .flex.flex-col
          label {{ $t('modal_print_eans.option_margin') }}
          input(
            type="range",
            min="-10",
            max="15",
            v-model="obj_code.textMargin"
          )
        .flex.flex-col
          label {{ $t('modal_print_eans.option_font_size') }}
          input(type="range", min="15", max="50", v-model="obj_code.fontSize")
      .p-3.grid.grid-cols-2.gap-4.border-b.border-gray-100
        .flex.flex-col
          label {{ $t('modal_print_eans.sub_title1') }}
          .flex
            .flex.items-center
              input.mr-1(type="checkbox", v-model="options_fonts.font_bold")
              .text-xs.font-bold Bold
            .flex.items-center.ml-2
              input.mr-1(type="checkbox", v-model="options_fonts.font_italic")
              .text-xs.italic Italic
            .flex.items-center.ml-2
              input.mr-1(type="checkbox", v-model="obj_code.flat")
              .text-xs {{ $t('modal_print_eans.option_font_none') }}
        .flex.flex-col
          label {{ $t('modal_print_eans.sub_title2') }}
          .flex
            .flex.items-center
              input.mr-1(
                type="radio",
                value="left",
                v-model="obj_code.textAlign"
              )
              .text-xs {{ $t('modal_print_eans.option_left') }}
            .flex.items-center.ml-2
              input.mr-1(
                type="radio",
                value="center",
                checked,
                v-model="obj_code.textAlign"
              )
              .text-xs {{ $t('modal_print_eans.option_center') }}
            .flex.items-center.ml-2
              input.mr-1(
                type="radio",
                value="right",
                v-model="obj_code.textAlign"
              )
              .text-xs {{ $t('modal_print_eans.option_right') }}
        .flex.flex-col
          label {{ $t('modal_print_eans.sub_title3') }}
          .flex
            .flex.items-center
              input.mr-1(
                type="radio",
                value="top",
                v-model="obj_code.textPosition"
              )
              .text-xs {{ $t('modal_print_eans.option_top') }}
            .flex.items-center.ml-2
              input.mr-1(
                type="radio",
                value="bottom",
                checked,
                v-model="obj_code.textPosition"
              )
              .text-xs {{ $t('modal_print_eans.option_bottom') }}
      .p-3.border-b.border-gray-100
        .flex.flex-col
          label {{ $t('modal_print_eans.sub_title4') }}
          .flex 
            .flex.items-center
              input.mr-1(type="radio", value="1", v-model="paper_columns")
              .text-xs {{ $t('modal_print_eans.option_colum_1') }}
            .flex.items-center.ml-2
              input.mr-1(
                type="radio",
                value="2",
                checked,
                v-model="paper_columns"
              )
              .text-xs {{ $t('modal_print_eans.option_colums', { number: '2' }) }}
            .flex.items-center.ml-2
              input.mr-1(type="radio", value="3", v-model="paper_columns")
              .text-xs {{ $t('modal_print_eans.option_colums', { number: '3' }) }}
            .flex.items-center.ml-2
              input.mr-1(type="radio", value="4", v-model="paper_columns")
              .text-xs {{ $t('modal_print_eans.option_colums', { number: '4' }) }}
          label.mt-3 {{ $t('modal_print_eans.sub_title5') }}
          input(v-model="code_copies", type="number")
      .flex.justify-end.p-3
        structure-button.text-white.bg-green-500.mr-3(
          :text="$t('globals.print')",
          @click="imprimir"
        )
        structure-button.text-white.bg-blue-600(
          :text="$t('modal_print_eans.button_download_png')",
          @click="download"
        )
</template>
<script>
import JsBarcode from "jsbarcode";
import printJS from "print-js";

export default {
  name: "modalEditorEan",
  emits: ["modal-close"],
  props: {
    ean: String,
  },
  data: () => ({
    obj_code: {
      format: "EAN13",
      width: 3,
      height: 100,
      textMargin: 0,
      fontSize: 20,
      fontOptions: "",
      textAlign: "center",
      textPosition: "bottom",
      flat: false,
    },
    options_fonts: {
      font_italic: false,
      font_bold: false,
    },
    paper_columns: 2,
    code_copies: 10,
  }),
  watch: {
    obj_code: {
      handler() {
        this.generateBarcode();
      },
      deep: true,
    },
    code_copies() {
      if (this.code_copies <= 1) this.code_copies = 1;
    },
    options_fonts: {
      handler(val) {
        var textFont = "";
        if (val.font_italic) {
          textFont += " italic ";
        }
        if (val.font_bold) {
          textFont += " bold ";
        }
        this.obj_code.fontOptions = textFont;
      },
      deep: true,
    },
  },
  created() {
    setTimeout(() => this.generateBarcode(), 250);
  },
  methods: {
    generateBarcode() {
      JsBarcode("#barcode", this.ean, this.obj_code);
    },
    download() {
      const filename = this.ean + ".png";
      const blob = this.$refs.code_to_print.src;
      if (navigator.msSaveBlob) {
        // IE10+ : (has Blob, but not a[download] or URL)
        fetch(this.$refs.code_to_print.src)
          .then((response) => response.blob())
          .then((blob) => {
            return navigator.msSaveBlob(blob, filename);
          });
        return true;
      }

      const element = document.createElement("a");
      element.style.display = "none";
      element.href = blob;
      element.download = filename;

      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    imprimir() {
      var img_png = this.$refs.code_to_print.src;
      var columns = 100 / this.paper_columns - 1;

      var copies = [];
      for (var i = this.code_copies - 1; i >= 0; i--) {
        copies[i] = img_png;
      }
      printJS({
        printable: copies,
        type: "image",
        header: "",
        imageStyle: "width:" + columns + "%;margin:5px 1px;float:left;",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.modal {
  z-index: 20;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

input[type="number"] {
  @apply rounded border border-gray-200 bg-gray-50 px-3 py-2 outline-none max-w-xs;
  font-size: 13px;
}
</style>